import { inject, Injectable } from '@angular/core';

import { OverlayEventDetail } from '@ionic/core';

import { CasinoGamePlayMode, ICasinoGame } from 'bp-framework/dist/casino/casino.interface';
import { IPrimaryPageItem } from 'bp-framework/dist/common/common.interface';
import { IDialogResponse } from 'bp-framework/dist/dialogs/dialog.interface';

import { DialogsService } from 'bp-angular-library';

import { ContentAbstractService } from '../../env-specific/env-abstracts';

import { PlatformService } from '../platform/platform.service';

import { CasinoSearchModalComponent } from 'src/app/shared/components/casino/casino-search-modal/casino-search-modal.component';
import { PlayGameDialogComponent } from 'src/app/shared/components/casino/play-game-dialog/play-game-dialog.component';
import { SignInSignUpComponent } from 'src/app/shared/components/forms/sign-in-sign-up/sign-in-sign-up.component';

import { BehaviorSubject } from 'rxjs';
import { sortBySortOrder } from 'src/app/shared/models/common/common.utils';
import { SignUpOptionType, SiSuViewType } from 'src/app/shared/models/ui/ui.interface';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private dialogsService: any = inject(DialogsService);
  private platformService: PlatformService = inject(PlatformService);
  private contentAbstractService: ContentAbstractService = inject(ContentAbstractService);
  public primaryPages$: BehaviorSubject<Partial<IPrimaryPageItem<number>>[]> = new BehaviorSubject<Partial<IPrimaryPageItem<number>>[]>([]);

  constructor() {
    this.getListOfPrimaryPages();
  }

  private async getListOfPrimaryPages(): Promise<void> {
    try {
      const pages: Partial<IPrimaryPageItem<number>>[] = await this.contentAbstractService.getListOfPrimaryPages();
      const sorted: Partial<IPrimaryPageItem<number>>[] = pages.sort(sortBySortOrder());
      this.primaryPages$.next(sorted);
    } catch (error) {
      console.error('getListOfPrimaryPages error', error);
    }
  }

  public async showSignInSignUpDialog(
    currentView: SiSuViewType,
    signUpOptionType: SignUpOptionType,
    showHeader = true,
    reloadAfterSuccess = true
  ): Promise<OverlayEventDetail<IDialogResponse<any>>> {
    return this.dialogsService.presentModal({
      component: SignInSignUpComponent,
      cssClass: 'sign-up-sign-in-modal',
      componentProps: {
        showHeader,
        currentView,
        signUpOptionType,
        reloadAfterSuccess
      }
    });
  }

  public async opaneCasinoGamesGlobalSearchDialog(): Promise<void> {
    const isMobileResolution: boolean = this.platformService.isMobileResolution();
    const initialBreakpoint: number = isMobileResolution ? 1 : this.calculateInitialBreakpoint(this.platformService.height());

    const dialogResult: OverlayEventDetail<IDialogResponse<any>> = await this.dialogsService.presentModal({
      component: CasinoSearchModalComponent,
      componentProps: {
        showHeader: isMobileResolution
      },
      cssClass: 'is-casino-search-modal',
      initialBreakpoint: initialBreakpoint,
      breakpoints: [0, 0.25, 0.5, initialBreakpoint],
      handleBehavior: 'cycle'
    });
  }

  private calculateInitialBreakpoint(fullHeight: number): number {
    const adjustedHeight = fullHeight - 80; // 80px is the height of the header on desktop
    const heightRatio = adjustedHeight / fullHeight;
    return heightRatio;
  }

  public async openCasinoGameInFullscreenDialog(game: Partial<ICasinoGame> | null, gamePlayMode: CasinoGamePlayMode | null): Promise<OverlayEventDetail<IDialogResponse<any>>> {
    return this.dialogsService.presentModal({
      component: PlayGameDialogComponent,
      componentProps: {
        game,
        gamePlayMode
      },
      cssClass: 'full-screen-modal'
    });
  }
}
