import { Routes } from '@angular/router';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import {
  CasinoGamePlayMode,
  IApiCasinoRequestParams,
  ICasinoGame,
  ICasinoGameLaunchDetails,
  ICasinoGamesAndTags,
  ICasinoGamesGroup,
  IJackpot
} from 'bp-framework/dist/casino/casino.interface';
import { IListItem, INavGroup, INavigationItem, IPrimaryPageItem } from 'bp-framework/dist/common/common.interface';
import { IBpGreekKenoRound, IBpKenoCoupon, IBpKenoCouponStatus, IBpKenoCouponSubmit } from 'bp-framework/dist/env-specific/betplatform/greek-keno/greek-keno.interface';
import { IAffiliateCode, IReferredPlayer } from 'bp-framework/dist/player/player.interface';
import { ITransactionGroup } from 'bp-framework/dist/transactions/transaction-groups.interface';
import { ICryptoDepositInit, ITransactionDetails, TransactionType } from 'bp-framework/dist/transactions/transactions.interface';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { BehaviorSubject } from 'rxjs';

export abstract class UserAbstractService {
  abstract playerRegisterWithUsernameAndPassword(username: string, password: string, phone: string, affiliateCode: string): Promise<Partial<IUserDetails> | null>;
  abstract loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null>;
  abstract changePassword(oldPassword: string, newPassword: string): Promise<string | null>;
  abstract refreshToken(): Promise<Partial<IUserDetails> | null>;
  abstract getUserProfile(): Promise<Partial<IUserDetails> | null>;
  abstract patchUserLanguage(langAlpha2Code: string): Promise<Partial<IUserDetails> | null>;
  abstract updateUserWithProfileData(): Promise<Partial<IUserDetails> | null>;
  abstract watchForLanguageChange(): void;
  abstract getAffiliatePlayers(): Promise<Partial<IReferredPlayer>[] | null>;
  abstract getAffiliateCodes(): Promise<Partial<IAffiliateCode>[] | null>;
  // TODO: Check should we create separate class for Player
  abstract getPlayerTransactions(
    start_date_iso: string,
    end_date_iso: string,
    limit: number,
    offset: number,
    transaction_types: TransactionType[]
  ): Promise<Partial<ITransactionDetails>[] | null>;
  abstract makeCryptoWithdrawRequest(wallet_id: number, amount: number, crypto_wallet_address: string): Promise<Partial<ITransactionDetails> | null>;
  abstract cancelCryptoWithdrawRequest(request_id: string): Promise<Partial<ITransactionDetails> | null>;
  abstract getCryptoDepositInit(): Promise<ICryptoDepositInit | null>;
}

export abstract class CasinoAbstractService {
  // Tags | Vendors | Categories | Subcategories
  abstract getAllCasinoGroups(): Promise<IApiPayload<Partial<ICasinoGamesGroup<number>>[]>>;
  abstract getSubcategoriesByCategoryId(categoryId: string): Promise<IApiPayload<Partial<IListItem<number>>[]>>;
  abstract getVendorsByCategoryIdAndSubCategoryId(categoryId: string, subCategoryId: string): Promise<IApiPayload<Partial<IListItem<number>>[]>>;
  // Games
  abstract getCasinoGames(params: IApiCasinoRequestParams): Promise<IApiPayload<ICasinoGamesAndTags>>;
  abstract getCasinoGameById(gameId: number): Promise<Partial<ICasinoGame> | null>;
  abstract getDetailsToLaunchGame(gameId: number, mode: CasinoGamePlayMode): Promise<ICasinoGameLaunchDetails>;
  abstract jackpots$: BehaviorSubject<IJackpot[] | null>;
  abstract getJackpotsListForPlayers(): Promise<any>;
  abstract updateJackpotValue(jackpot: IJackpot): void;
  abstract processJackpotAwardedEvent(playerId: string, jackpot: IJackpot): void;
  // Favorites
  abstract getFavoriteGames(params: IApiCasinoRequestParams): Promise<IApiPayload<ICasinoGamesAndTags>>;
  abstract getIdsOfFavoriteGames(): Promise<number[]>;
  abstract addGameToFavorites(gameId: number): Promise<IApiPayload<unknown>>;
  abstract removeGameFromFavorites(gameId: number): Promise<IApiPayload<unknown>>;
  // Greek Keno
  abstract getKenoRounds(params?: { limit?: number }): Promise<IApiPayload<IBpGreekKenoRound[]>>;
  abstract submitKenoCoupon(numbers: number[], stake: number): Promise<IApiPayload<IBpKenoCouponSubmit | null>>;
  abstract getKenoCoupons(params?: { status?: IBpKenoCouponStatus; limit?: number }): Promise<IApiPayload<IBpKenoCoupon[]>>;
}

export abstract class BettingAbstractService {
  abstract test(): void;
}

export abstract class BackendEventsAbstractService {
  abstract subscribeToBackendEvents(): void;
}

export abstract class ContentAbstractService {
  abstract getListOfPrimaryPages(): Promise<Partial<IPrimaryPageItem<number>>[]>;
  abstract buildAllRoutes(): Routes;
  abstract buildSidemenuRoutes(): Partial<INavGroup>[];
  abstract buildMainMenuRoutes(): Partial<INavigationItem<number>>[];
  abstract buildSiteFooterNavLinks(): Partial<INavGroup>[];
  abstract buildSecondaryPagesNavLinks(): Partial<INavGroup>[];
}

export abstract class GlobalAbstractService {
  abstract financeGetTransactionGroups(): Promise<IApiPayload<ITransactionGroup[]> | null>;
}
