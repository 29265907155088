import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ICasinoGamesGroup } from 'bp-framework/dist/casino/casino.interface';

import { CasinoService } from '../../../../pages-casino/casino.service';

@Injectable({
  providedIn: 'root'
})
export class CasinoCategoriesStripeService {
  private router: Router = inject(Router);
  private casinoService: CasinoService = inject(CasinoService);

  public categories: Partial<ICasinoGamesGroup<number>>[] = [];

  constructor() {
    this.initialize();
  }

  private initialize(): void {
    this.casinoService.allCasinoGroups$.subscribe((groups: Partial<ICasinoGamesGroup<number>>[]) => {
      this.categories = groups;
    });
  }

  public handleSelectedCategoryChange(category: Partial<ICasinoGamesGroup<number>>): void {
    if (category?.config?.launchUrl) {
      this.router.navigateByUrl(`/${category.config.launchUrl}`);
      return;
    }

    this.casinoService.selectedProvider$.next(null);
    this.casinoService.selectedSubCategory$.next(null);
    this.casinoService.selectedGroup$.next(category);
  }

  // public handleActionOnMenuItem(page: Partial<IPrimaryPageItem<number>>): void {
  //   console.log('handleActionOnMenuItem: ', page);
  //   if (page?.launchUrl) {
  //     console.log(`Navigating to: ${page.launchUrl}`);
  //     this.router.navigateByUrl(`/${page.launchUrl}`);
  //   } else if (page?.groupId) {
  //     console.log('MATHING BY GROUP ID  ');
  //     this.router.navigateByUrl(`/${ROUTE_PATHS.casino}/games`);

  //     const matchingGroup: Partial<IListItem<number>> | undefined = this.casinoCategoriesStripeService?.categories?.find(
  //       (category: Partial<IListItem<number>>) => category?.value === page.groupId
  //     );

  //     if (matchingGroup) {
  //       this.casinoService?.selectedGroup$.next(matchingGroup);
  //     }
  //   }
  // }
}
