import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateService } from '@ngx-translate/core';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import { transformToTransactionDetails } from 'bp-framework/dist/env-specific/betplatform/transactions/transactions.mappers';
import { bpMergeUserDetails } from 'bp-framework/dist/env-specific/betplatform/user/user.mappers';
import { IAffiliateCode, IReferredPlayer } from 'bp-framework/dist/player/player.interface';
import { ICryptoDepositInit, ITransactionDetails, TransactionType } from 'bp-framework/dist/transactions/transactions.interface';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { BetPlatformApiAdapterService, I18nService } from 'bp-angular-library';

import { UserAbstractService } from '../../env-abstracts';

import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserBetplatformService extends UserAbstractService {
  private translateService: TranslateService = inject(TranslateService);
  private i18nService: I18nService = inject(I18nService);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private authService: AuthenticationService = inject(AuthenticationService);
  private bpApiAdapterService: BetPlatformApiAdapterService = inject(BetPlatformApiAdapterService);

  public async playerRegisterWithUsernameAndPassword(username: string, password: string, phone: string, affiliateCode: string): Promise<Partial<IUserDetails> | null> {
    try {
      const registerPayload: IApiPayload<Partial<IUserDetails>> = await this.bpApiAdapterService.playerRegisterWithUsernameAndPassword(username, password, phone, affiliateCode);

      if (!registerPayload?.data?.id) {
        throw new Error(this.translateService.instant('notifications.userRegistrationFailedMissingId'));
      }

      await this.authService.userAuthChanged(registerPayload?.data);

      const loggedInUser: Partial<IUserDetails> | null = await this.loginWithUsernameAndPassword(username, password);

      return loggedInUser;
    } catch (error: unknown) {
      throw new Error(this.translateService.instant('notifications.somethingWentWrong') + (error as any)?.message || '');
    }
  }

  public async loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null> {
    try {
      const loginPayload: IApiPayload<Partial<IUserDetails>> = await this.bpApiAdapterService.userLoginWithUsernameAndPassword(username, password);
      await this.authService.userAuthChanged(loginPayload?.data);
      const getProfilePayload: IApiPayload<Partial<IUserDetails>> = await this.bpApiAdapterService.playerGetProfile();
      const currentDetails: Partial<IUserDetails> | null = this.authService.user$.value;
      const mergedValue: Partial<IUserDetails> | null = bpMergeUserDetails(currentDetails, getProfilePayload?.data);
      await this.authService.userAuthChanged(mergedValue);
      return mergedValue;
    } catch (error) {
      throw new Error(this.translateService.instant('notifications.failedToLoginCheckCredentialsOrTryLater'));
    }
  }

  public async refreshToken(): Promise<Partial<IUserDetails> | null> {
    try {
      const refreshToken: string | undefined = await this.authService.user$.value?.auth?.refreshToken;

      if (!refreshToken) {
        throw new Error(this.translateService.instant('notifications.failedToRetreiveRefreshToken'));
      }

      const loginPayload: IApiPayload<Partial<IUserDetails>> = await this.bpApiAdapterService.userRefreshToken(refreshToken);

      if (!loginPayload?.data?.auth?.token) {
        throw new Error(this.translateService.instant('notifications.failedToRefreshToken'));
      }

      await this.authService.userAuthChanged(loginPayload?.data);
      const getProfilePayload: IApiPayload<Partial<IUserDetails>> = await this.bpApiAdapterService.playerGetProfile();
      const mergedValue: Partial<IUserDetails> | null = bpMergeUserDetails(this.authService.user$.value, getProfilePayload?.data);
      await this.authService.userAuthChanged(mergedValue);

      return mergedValue;
    } catch (error: unknown) {
      throw new Error(this.translateService.instant('notifications.failedToRefreshToken') + (error as any)?.message || '');
    }
  }

  // TODO: Revisit naming of this method. It should be more descriptive. Everyone are 'user' but we also have 'player' and 'profile' in the same context
  public async getUserProfile(): Promise<Partial<IUserDetails> | null> {
    try {
      const payload: IApiPayload<Partial<IUserDetails>> = await this.bpApiAdapterService.playerGetProfile();
      return payload?.data;
    } catch (error) {
      throw new Error(this.translateService.instant('notifications.failedToRetreiveUserProfileData'));
    }
  }

  public async changePassword(oldPassword: string, newPassword: string): Promise<null> {
    try {
      const result: IApiPayload<unknown> = await this.bpApiAdapterService.userChangePassword(oldPassword, newPassword);
      return null;
    } catch (error) {
      throw new Error(this.translateService.instant('notifications.failedToChangePassword'));
    }
  }

  public async patchUserLanguage(lang: string): Promise<Partial<IUserDetails> | null> {
    try {
      const payload: IApiPayload<Partial<IUserDetails>> = await this.bpApiAdapterService.userPatchLanguage(lang);
      return payload?.data;
    } catch (error) {
      throw new Error(this.translateService.instant('notifications.failedToRetreiveUserProfileData'));
    }
  }

  public async updateUserWithProfileData(): Promise<Partial<IUserDetails> | null> {
    const freshData: Partial<IUserDetails> | null = await this.getUserProfile();
    await this.authService.userDetailsChanged(freshData);
    return this.authService.user$.value;
  }

  public watchForLanguageChange(): void {
    this.i18nService.saveNewlySelectedLanguageToUserProfile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((langAlpha2Code: string) => {
      // Handle additional actions on language change, e.g., refreshing data, updating UI, etc.
      // Update user language in the backend
      if (this.authService.user$.value?.id) {
        this.patchUserLanguage(langAlpha2Code);
        this.authService.userDetailsChanged({ attributes: { preferredLang: langAlpha2Code } });
      }
    });
  }

  public async getAffiliatePlayers(): Promise<IReferredPlayer[]> {
    try {
      const payload: IApiPayload<IReferredPlayer[]> = await this.bpApiAdapterService.playerGetReferredPlayers({});
      return payload?.data || [];
    } catch (error) {
      throw new Error('Failed to retrieve affiliate players');
    }
  }

  public async getAffiliateCodes(): Promise<IAffiliateCode[]> {
    try {
      const result: IApiPayload<IAffiliateCode[]> = await this.bpApiAdapterService.playerGetAffiliateCodes({});
      return result?.data || [];
    } catch (error) {
      throw new Error('Failed to retrieve affiliate codes');
    }
  }

  //#region Transactions
  public async getPlayerTransactions(
    start_date_iso: string,
    end_date_iso: string,
    limit: number,
    offset: number,
    transaction_types: TransactionType[]
  ): Promise<Partial<ITransactionDetails>[] | null> {
    try {
      const result: IApiPayload<Partial<ITransactionDetails>[]> = await this.bpApiAdapterService.playerGetTransactions(
        start_date_iso,
        end_date_iso,
        limit,
        offset,
        transaction_types
      );

      return result?.data || [];
    } catch (error) {
      throw new Error('Failed to retrieve list of transactions');
    }
  }

  public async getCryptoDepositInit(): Promise<ICryptoDepositInit | null> {
    try {
      const payload: IApiPayload<ICryptoDepositInit> = await this.bpApiAdapterService.playerInitiateCryptoDeposit();

      if (!payload?.data?.acquiringUrl) {
        throw new Error('Crypto Deposit initialization failed! Data is missing');
      }

      return payload.data;
    } catch (error) {
      throw new Error('Failed to initialize crypto deposit');
    }
  }

  public async makeCryptoWithdrawRequest(wallet_id: number, amount: number, crypto_wallet_address: string): Promise<Partial<ITransactionDetails> | null> {
    try {
      const initPayload: IApiPayload<{
        requestId?: string;
      }> = await this.bpApiAdapterService.playerInitiateCryptoWithdrawal(wallet_id, amount, crypto_wallet_address);
      const submitPayload: IApiPayload<Partial<ITransactionDetails>> = await this.bpApiAdapterService.playerSubmitCryptoWithdrawal(initPayload?.data?.requestId || '');

      if (!submitPayload?.data) {
        throw new Error('Withdraw request failed. Transaction data is missing.');
      }
      const tmpTransaction: Partial<ITransactionDetails> = transformToTransactionDetails(submitPayload.data);
      return tmpTransaction;
    } catch (error) {
      throw new Error('Failed to make crypto withdraw request');
    }
  }

  public async cancelCryptoWithdrawRequest(request_id: string): Promise<Partial<ITransactionDetails> | null> {
    try {
      const payload: IApiPayload<Partial<ITransactionDetails>> = await this.bpApiAdapterService.playerCancelCryptoWithdrawal(request_id);

      if (!payload?.data) {
        throw new Error('Transaction cancellation request failed. Transaction data is missing.');
      }

      return payload?.data;
    } catch (error) {
      throw new Error('Failed to cancel crypto withdraw request');
    }
  }
  //#endregion
}
