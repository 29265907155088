<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="handleSubmit()" (keydown.enter)="handleSubmit()">
  <!-- Username -->
  <ion-input [attr.aria-label]="'commons.username' | translate" fill="outline" [placeholder]="'commons.username' | translate" formControlName="username">
    <span slot="start" class="ph ph-user"></span>
  </ion-input>
  <!-- Password -->
  <app-password-input [labelT9nKey]="'commons.password'" [placeholderT9nKey]="'commons.password'" [fcName]="'password'"></app-password-input>
  <!-- Forgot your password? -->
  <div class="is-forgot-pass-link">{{ 'commons.forgotYourPassword' | translate }}</div>
  <!-- Submit button -->
  <ion-button class="is-submit-button" size="large" type="submit" [disabled]="formGroup.invalid">{{ 'commons.signIn' | translate }}</ion-button>
  <!-- Don't have an account? -->
  <div class="is-dont-have-account-link">
    {{ 'commons.dontHaveAnAccount' | translate }} <a (click)="siSuService.setView('sign-up')">{{ 'commons.signUp' | translate }}</a>
  </div>
</form>
