import { Location } from '@angular/common';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';

import { CasinoGamePlayMode } from 'bp-framework/dist/casino/casino.interface';

import { BehaviorSubject, debounceTime, filter, pairwise } from 'rxjs';
import { ROUTE_PATHS } from 'src/app/shared/models/routing/routing.const';
import { IQueryParamsMap } from 'src/app/shared/models/routing/routing.interface';
import { convertParamMapToQueryParamsMap } from 'src/app/shared/models/routing/routing.utils';

@Injectable({
  providedIn: 'root'
})
export class RouteManagementService {
  private destroyRef: DestroyRef = inject(DestroyRef);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);
  private location: Location = inject(Location);

  public navigationHistory: string[] = [];

  public queryParamsMap$: BehaviorSubject<IQueryParamsMap> = new BehaviorSubject<IQueryParamsMap>({});

  constructor() {
    this.subscribeToQueryParams();
    this.subscribeToRouterEvents();
  }

  private subscribeToQueryParams(): void {
    this.activatedRoute?.queryParamMap?.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(50)).subscribe((queryParamsMap: ParamMap) => {
      this.queryParamsMap$.next(convertParamMapToQueryParamsMap(queryParamsMap));
    });
  }

  private subscribeToRouterEvents(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((e: any) => e instanceof NavigationEnd),
        pairwise()
      )
      .subscribe(([prev, current]: [NavigationEnd, NavigationEnd]) => this.handleNavigationEvents(prev, current));
  }

  private handleNavigationEvents(prev: NavigationEnd, current: NavigationEnd): void {
    this.navigationHistory.push(current?.urlAfterRedirects);
  }

  public getCurrentQueryParams(): IQueryParamsMap {
    return convertParamMapToQueryParamsMap(this.activatedRoute?.snapshot?.queryParamMap);
  }

  public mergeQueryParams(queryParams: IQueryParamsMap): void {
    this.router.navigate([], { queryParams: queryParams, queryParamsHandling: 'merge' });
  }

  public navigateToCasinoView(view: 'all-games' | 'preview' | 'play-real' | 'play-demo', gameId?: number): void {
    if (view === 'all-games') {
      this.router.navigate([ROUTE_PATHS.casino], { queryParams: {}, queryParamsHandling: 'merge' });
    } else if (gameId && view === 'preview') {
      this.router.navigateByUrl(`${ROUTE_PATHS.casino}/games/${gameId}/preview`);
      this.router.navigate([ROUTE_PATHS.casino, 'games', gameId, 'preview'], { queryParams: {}, queryParamsHandling: 'merge' });
    } else if (gameId && view === 'play-real') {
      const playMode: CasinoGamePlayMode = 'real';
      this.router.navigate([ROUTE_PATHS.casino, 'games', gameId, 'play', playMode], { queryParams: {}, queryParamsHandling: 'merge' });
    } else if (gameId && view === 'play-demo') {
      const playMode: CasinoGamePlayMode = 'demo';
      this.router.navigate([ROUTE_PATHS.casino, 'games', gameId, 'play', playMode], { queryParams: {}, queryParamsHandling: 'merge' });
    }
  }

  public handleGoBack(fallbackPath = '/'): void {
    this.navigationHistory.pop();
    console.log('route management service GO BACK');

    if (this.navigationHistory.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl(fallbackPath);
    }
  }
}
