import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';

import { IonicModule, MenuController } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { IListItem, INavGroup, INavItem, IPrimaryPageItem } from 'bp-framework/dist/common/common.interface';

import { LanguageButtonComponent, PROJECT_ENV_CONFIG_TOKEN, ThemeToggleComponent } from 'bp-angular-library';

import { ContentAbstractService } from 'src/app/core/env-specific/env-abstracts';

import { CasinoCategoriesStripeService } from '../../casino/casino-categories-stripe/casino-categories-stripe.service';
import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { CasinoService } from 'src/app/pages-casino/casino.service';

import { SvgIconComponent } from '../../svg-icon/svg-icon.component';

import { filter } from 'rxjs';
import { IEnvApiBase, IEnvConfigPlayerFrontend } from 'src/app/shared/models/configuration/configuration.interface';
import { ROUTE_PATHS } from 'src/app/shared/models/routing/routing.const';
import { PACKAGE_DETAILS } from 'src/environments/package-details';

const SUPPORT_NAV_GROUP: Partial<INavItem>[] = [
  {
    label: 'Contact',
    t9nKey: 'pages.support.pages.contact.title',
    path: `/${ROUTE_PATHS.support.path}/${ROUTE_PATHS.support.children.contact}`
  },
  {
    label: 'Privacy Policy',
    t9nKey: 'pages.support.pages.privacyPolicy.title',
    path: `/${ROUTE_PATHS.support.path}/${ROUTE_PATHS.support.children.privacyPolicy}`
  },
  {
    label: 'Terms of Use',
    t9nKey: 'pages.support.pages.termsOfUse.title',
    path: `/${ROUTE_PATHS.support.path}/${ROUTE_PATHS.support.children.termsOfUse}`
  },
  {
    label: 'Responsible Gaming Policy',
    t9nKey: 'pages.support.pages.responsibleGamingPolicy.title',
    path: `/${ROUTE_PATHS.support.path}/${ROUTE_PATHS.support.children.responsibleGamingPolicy}`
  },
  {
    label: 'AML & KYC Policy',
    t9nKey: 'pages.support.pages.amlKycPolicy.title',
    path: `/${ROUTE_PATHS.support.path}/${ROUTE_PATHS.support.children.amlKycPolicy}`
  },
  {
    label: 'Affiliate',
    t9nKey: 'commons.affiliate',
    path: `/${ROUTE_PATHS.myProfile.path}/${ROUTE_PATHS.myProfile.children.myAffiliate}`
  }
];

@Component({
  selector: 'app-sidemenu-primary',
  standalone: true,
  imports: [CommonModule, IonicModule, RouterLink, RouterLinkActive, ThemeToggleComponent, LanguageButtonComponent, TranslateModule, SvgIconComponent],
  templateUrl: './sidemenu-primary.component.html',
  styleUrl: './sidemenu-primary.component.scss'
})
export class SidemenuPrimaryComponent implements OnInit {
  private destroyRef: DestroyRef = inject(DestroyRef);
  public authService: AuthenticationService = inject(AuthenticationService);
  private contentAbstractService: ContentAbstractService = inject(ContentAbstractService);
  private menuCtrl: MenuController = inject(MenuController);
  private casinoCategoriesStripeService: CasinoCategoriesStripeService = inject(CasinoCategoriesStripeService);
  private casinoService: CasinoService = inject(CasinoService);
  private layoutService: LayoutService = inject(LayoutService);
  private router: Router = inject(Router);
  public projectConfig: IEnvConfigPlayerFrontend<IEnvApiBase> = inject<IEnvConfigPlayerFrontend<IEnvApiBase>>(PROJECT_ENV_CONFIG_TOKEN);

  public appVersion: any = `${this.projectConfig?.projectName} v.${PACKAGE_DETAILS.version}-${this.projectConfig?.environmentName}-${this.projectConfig?.features.frontendMode}`;

  public showThemeToggle: boolean = this.projectConfig?.environmentName === 'dev';

  public sideMenuGroups: Partial<INavGroup>[] = [];

  public secondaryPages: Partial<INavItem>[] = SUPPORT_NAV_GROUP;

  public mainPages: Partial<IPrimaryPageItem<number>>[] = [];
  public remainingMainPages: Partial<IPrimaryPageItem<number>>[] = [];

  //#region lifecycle hooks
  ngOnInit(): void {
    this.subscribeToRouterEvents();
    this.sideMenuGroups = this.contentAbstractService.buildSidemenuRoutes();

    this.layoutService.primaryPages$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((pages: Partial<IPrimaryPageItem<number>>[]): void => {
      this.mainPages = JSON.parse(JSON.stringify(pages || []));
    });

    this.remainingMainPages = [];
  }
  //#endregion

  public handleActionOnMenuItem(page: Partial<IPrimaryPageItem<number>>): void {
    if (page?.launchUrl) {
      this.router.navigateByUrl(`/${page.launchUrl}`);
    } else if (page?.groupId) {
      this.router.navigateByUrl(`/${ROUTE_PATHS.casino}/games`);

      const matchingGroup: Partial<IListItem<number>> | undefined = this.casinoCategoriesStripeService?.categories?.find(
        (category: Partial<IListItem<number>>) => category?.value === page.groupId
      );

      if (matchingGroup) {
        this.casinoService?.selectedGroup$.next(matchingGroup);
      }
    }

    this.closeMenu();
  }

  private subscribeToRouterEvents(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe((event: any): void => this.resolveCurrentSegment(event?.urlAfterRedirects));
  }

  private resolveCurrentSegment(urlAfterRedirect: string): void {
    let matchingNavItem: Partial<INavGroup> | null = null;

    this.sideMenuGroups?.forEach((item: Partial<INavGroup>) => {
      if (item.path && urlAfterRedirect.includes(item.path)) {
        matchingNavItem = item;
        matchingNavItem.expanded = Array.isArray(item?.children) && item?.children?.length > 0;
      }
    });
  }

  public closeMenu(): void {
    this.menuCtrl.close('primary-sidemenu');
  }
}
