import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { IListItem } from 'bp-framework/dist/common/common.interface';

import { SignUpOptionType } from 'src/app/shared/models/ui/ui.interface';

const OPTIONS: Partial<IListItem<SignUpOptionType>>[] = [
  {
    value: 'email',
    label: 'Via Email',
    labelT9nKey: 'dialogs.siSuDialog.viaEmail',
    iconName: 'ph-envelope-simple'
  },

  {
    value: 'telegram',
    label: 'Via Telegram',
    labelT9nKey: 'dialogs.siSuDialog.viaTelegram',
    iconName: 'ph-telegram-logo'
  }
];

@Component({
  selector: 'app-sign-up-options-buttons',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
  templateUrl: './sign-up-options-buttons.component.html',
  styleUrl: './sign-up-options-buttons.component.scss'
})
export class SignUpOptionsButtonsComponent {
  @Input() activeType: SignUpOptionType = 'phone';
  @Output() handleSelected: EventEmitter<SignUpOptionType> = new EventEmitter<SignUpOptionType>();

  public options: Partial<IListItem<SignUpOptionType>>[] = OPTIONS;
}
