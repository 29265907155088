<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="handleSubmit()" (keydown.enter)="handleSubmit()">
  <!-- Username -->
  <ion-input [attr.aria-label]="'commons.username' | translate" fill="outline" [placeholder]="'commons.username' | translate" formControlName="username">
    <span slot="start" class="ph ph-user"></span>
  </ion-input>
  <!-- Password -->
  <app-password-input [label]="'commons.password' | translate" [placeholder]="'commons.password' | translate" [fcName]="'password'"></app-password-input>
  <!-- Confirm password -->
  <app-password-input
    [label]="'commons.passwordConfirmation' | translate"
    [placeholder]="'commons.passwordConfirmation' | translate"
    [fcName]="'passwordConfirm'"></app-password-input>
  <!-- Phone number -->
  <app-phone-input [fcName]="'phone'"></app-phone-input>
  <!-- Affiliate Code -->
  <ion-input [attr.aria-label]="'commons.affiliateCode' | translate" fill="outline" [placeholder]="'commons.enterCode' | translate" formControlName="bannerTag">
    <span slot="start" class="ph ph-hash"></span>
  </ion-input>
  <!-- Confirm -->
  <div>
    {{ 'dialogs.siSuDialog.iConfirmLegalAge' | translate }} <a>{{ 'dialogs.siSuDialog.iConfirmWebsiteRules' | translate }}</a>
  </div>
  <!-- Submit button -->
  <ion-button class="is-submit-button" size="" type="submit" [disabled]="formGroup.invalid">{{ 'commons.signUp' | translate }}</ion-button>
</form>
